export const validateEmail = (email: string): boolean => {
  const re = /^([a-zA-Z0-9_+][a-zA-Z0-9_+.-]*)+@([\w-]+\.)+[\w]{2,4}$/;
  
  return re.test(email);
};

export const validatePassword = (password: string): boolean => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

  return re.test(password);
};

import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./components/Layout/Layout";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import AuthorizedRoutes from "./components/AuthorizedRoutes";
import UnauthorizedAccess from "./pages/UnauthorizedAccess";
import Logout from "./pages/Logout";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Authorized Routes */}
        <Route path="/" element={<AuthorizedRoutes />}>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/logout" element={<Logout />} />
        </Route>

        <Route path="/unauthorized" element={<UnauthorizedAccess />} />
      </Route>
    </Routes>
  );
};

export default App;

import { Field, Form, FormElement } from "@progress/kendo-react-form";
import CustomInput from "../components/CustomInput";
import { Button } from "@progress/kendo-react-buttons";
import { validateEmail } from "../utils/validator";
import defaultLogo from "../assets/images/logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ForgotPasswordRequest } from "../types/ForgotPasswordRequest";
import accountService from "../services/account.service";
import { useEffect, useState } from "react";
import { Error } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import useLocale from "../hooks/useLocale";
import Swal from "sweetalert2";
import { Dictionary } from "../types/Dictionary";
import useBranding from "../hooks/useBranding";


const ForgotPassword: React.FC = () => {
  const localeCtx = useLocale();
  const brandingCtx = useBranding();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["forgotpassword"]);

  const clientId = searchParams.get("clientId");
  const redirectUri = searchParams.get("redirectUri");

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setTranslationsLoading(true);
        const resp = await localeCtx?.setComponentTranslations("forgotpassword");
        setTranslations(resp);
      } catch (err) {
        setTranslations(
          localeCtx?.selectedLocale?.previous.componentTranslations["forgotpassword"]
        );
        localeCtx?.setPreviousAppLocale("forgotpassword");
        if (localeCtx?.localeSwitchFailed) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Couldn't Switch Language",
          });
        }
      } finally {
        setTimeout(() => {
          setTranslationsLoading(false);
        }, 100);
      }
    };

    if (!localeCtx?.selectedLocale?.current.componentTranslations["forgotpassword"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (!clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    }
  }, []);

  //#region Locale Translation Methods
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  //#endregion Locale Translation Methods


  const submitHandler = async (values: { [name: string]: any }) => {
    try {
      setLoading(true);
      if (clientId) {
        const reqPayload: ForgotPasswordRequest = {
          email: values.email,
        };
        await accountService.forgotPassword(reqPayload, clientId);
        setSuccess(true);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errMsg = err.response?.data?.error;
        setError(errMsg);
      }
    } finally {
      setLoading(false);
    }
  };

  const emailValidator = (value: string) => {
    if (!validateEmail(value)) {
      return `${translationsLoading
        ? "Invalid email address."
        : fetchLabelKeyTranslation(
          "InvalidEmailText",
          "Invalid email address."
        )
        }`;
    }

    return "";
  };

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="loginBg h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                    {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                    <img
                      src={
                        brandingCtx?.branding?.logos.find(
                          (l) => l.name === "HeaderPrimaryLogo"
                        )?.logoImageUrl
                      }
                      alt="Logo"
                    />
                  </div>}
                {success ? (
                  <span className="tx-green fs-18">
                    {translationsLoading
                      ? "An Email with Reset Password Link has been sent to your registered email account."
                      : fetchLabelKeyTranslation(
                        "EmailSentText",
                        "An Email with Reset Password Link has been sent to your registered email account."
                      )
                    }
                  </span>
                ) : (
                  <Form
                    initialValues={{
                      email: "",
                    }}
                    onSubmit={submitHandler}
                    render={(formRenderProps) => (
                      <FormElement style={{ maxWidth: "100%" }}>
                        <fieldset className={"k-form-fieldset"}>
                          <Error>{error}</Error>
                          <div className="m-b-15">
                            <Field
                              placeholder={`${translationsLoading
                                ? "Email"
                                : fetchLabelKeyTranslation(
                                  "EmailFieldPlaceholder",
                                  "Email"
                                )
                                }`}
                              name="email"
                              type="email"
                              value={formRenderProps.valueGetter("email")}
                              component={CustomInput}
                              validator={emailValidator}
                            />
                          </div>
                          <span className="fs-16">
                            {translationsLoading
                              ? "Reset password link will be sent to this email"
                              : fetchLabelKeyTranslation(
                                "EmailLinkText",
                                "Reset password link will be sent to this email"
                              )
                            }
                          </span>
                        </fieldset>
                        <div className="k-form-buttons d-flex justify-content-center m-t-15">
                          <Button
                            type={"submit"}
                            style={{
                              width: "100%",
                              textTransform: "uppercase",
                            }}
                            className={`btn bg-primary text-white fs-16 p-t-7 p-b-7`}
                          >
                            {loading ? (
                              <Loader
                                size="small"
                                type="infinite-spinner"
                                themeColor="light"
                              />
                            ) : (
                              `${translationsLoading
                                ? "Submit"
                                : fetchLabelKeyTranslation(
                                  "SubmitButton",
                                  "Submit"
                                )
                              }`
                            )}
                          </Button>
                        </div>
                      </FormElement>
                    )}
                  />
                )}
                <p className="text-center text-muted m-t-6 m-b-0 fs-16">
                  {
                    translationsLoading
                      ? "Back To"
                      : fetchLabelKeyTranslation(
                        "BackToText",
                        "Back To"
                      )
                  }{" "}
                  <Link
                    to={`/login?clientId=${clientId}${redirectUri !== null ? `&redirectUri=` + redirectUri : ""
                      }`}
                    className="forgotPasswordLbl btn-link"
                  >
                    {
                      translationsLoading
                        ? "LoginText"
                        : fetchLabelKeyTranslation(
                          "LoginText",
                          "Login"
                        )
                    }
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import axios from "axios";
import { API_BASE_URL } from "../constants";
import { AppLocale } from "../contexts/LocaleProvider";
import { parseJSON } from "../hooks/useSessionStorage";
import { appUrl } from "..";

console.log(API_BASE_URL);

export const customAxios = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

customAxios.interceptors.request.use((config) => {
  const locale = parseJSON<AppLocale>(sessionStorage.getItem("AppLocale"))
    ?.current.locale.code;
  config.headers = config.headers ?? {};
  config.headers.set("x-tollring-app-url", appUrl ?? "");
  config.headers.set("x-tollring-app-locale", locale ?? "");
  return config;
});

import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import accountService from "../services/account.service";
import { AxiosError } from "axios";
import { Error } from "@progress/kendo-react-labels";
import { Dictionary } from "../types/Dictionary";
import useLocale from "../hooks/useLocale";
import Swal from "sweetalert2";

const Logout: React.FC = () => {
  const localeCtx = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["logout"]);

  const clientId = searchParams.get("clientId");
  const redirectUri = searchParams.get("redirectUri");

  const logoutUser = async () => {
    try {
      if (clientId) {
        const success = await accountService.logout(clientId);
        navigate(
          `/login?clientId=${clientId}${
            redirectUri !== null ? `&redirectUri=` + redirectUri : ""
          }`,
          {
            replace: true,
          }
        );
      }
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode === "TOKEN_INVALID_OR_EXPIRED") {
          navigate(`/unauthorized`, { replace: true });
        } else {
          const errMsg = err.response?.data?.error;
          setError(errMsg);
        }
      }
    }
  };

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setTranslationsLoading(true);
        const resp = await localeCtx?.setComponentTranslations("logout");
        setTranslations(resp);
      } catch (err) {
        setTranslations(
          localeCtx?.selectedLocale?.previous.componentTranslations["logout"]
        );
        localeCtx?.setPreviousAppLocale("logout");
        if (localeCtx?.localeSwitchFailed) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Couldn't Switch Language",
          });
        }
      } finally {
        setTimeout(() => {
          setTranslationsLoading(false);
        }, 100);
      }
    };

    if (!localeCtx?.selectedLocale?.current.componentTranslations["logout"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);
  useEffect(() => {
    if (!clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    } else {
      logoutUser();
    }
  }, []);

  //#region Locale Translation Methods
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  //#endregion Locale Translation Methods

  return (
    <div className="TemplateMessageOuter">
      <div className="TemplateMessageInner">
        <div className="TemplateMessageBox">
          <div className="errorContainer float-left w-100 text-center">
            {!error && <Loader type={"infinite-spinner"} className="m-b-10" />}
            <div className="errorTxt">
              <div
                className={`errorTxt-h fs-22 font-weight-semi p-b-20 ${
                  error && "tx-red"
                }`}
              >
                {error ??
                  `${
                    translationsLoading
                      ? "Please wait while we log you out of your accounts"
                      : fetchLabelKeyTranslation(
                          "LogoutMsg",
                          "Please wait while we log you out of your accounts"
                        )
                  }`}
              </div>
              <div className="errorTxt-p fs-16 p-b-20">
                {error ? (
                  <p className="text-center text-muted m-t-6 m-b-0 fs-16">
                    {translationsLoading
                      ? "Back To"
                      : fetchLabelKeyTranslation("BackToText", "Back To")}{" "}
                    <Link
                      to={`/login?clientId=${clientId}${
                        redirectUri !== null
                          ? `&redirectUri=` + redirectUri
                          : ""
                      }`}
                      className="forgotPasswordLbl btn-link"
                    >
                      {translationsLoading
                        ? "Login"
                        : fetchLabelKeyTranslation("LoginText", "Login")}
                    </Link>
                  </p>
                ) : (
                  `${
                    translationsLoading
                      ? "You will be automatically redirected to the login page..."
                      : fetchLabelKeyTranslation(
                          "RedirectionText",
                          "You will be automatically redirected to the login page..."
                        )
                  }`
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
